import {FormEventHandler, useCallback, useEffect, useMemo, useRef} from 'react'
import { isEmpty } from 'lodash'

import { useSelector } from 'react-redux'
import FormikRadioGroup from '@ui-components-3/compat/lib/components/FormikRadioGroup'
import PlusIcon from '@ui-components-3/icons/lib/duotone/CirclePlus'

import RadioCardGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import withForm from '../../../hoc/withForm'
import * as MemberSelectors from '../../../redux/selectors/members'
import TestIds from '../../../util/TestIds'
import { phoneFormat } from '../../../util/formatters'
import { determineModalityRestrictions, EVisitModalities } from '../../../util/visit-modalities'
import VisitModalityIllustrationUrl from '../../../assets/images/visit_modality_illustration.svg'
import VisitModality from '../../../components/visit-creation/VisitModality'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PendingVisit from 'types/visit/pendingVisit'
import { AnyObjectSchema } from 'yup'
import { Phone } from 'types/member/member'
import { FormikErrors } from 'formik'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import { toast } from 'util/toast'
import { ModalRef } from '@ui-components-3/ui/lib/components/Modal'
import { PhoneFormValues } from 'schemas/PhoneSchema'
import PhoneFormDialog from 'forms/PhoneFormDialog'
import { isSuccess } from 'util/helpers'
import AslInterpreterCheckboxToggle from 'components/feature-flag/toggles/AslInterpreterCheckboxToggle'

type MinOption = {
  value: EVisitModalities
}

type FormValues = { modality: EVisitModalities; phone: null | Phone; phoneId: string; aslInterpreterNeeded: boolean }

const getOptionValue = (o: MinOption) => o.value
const getOptionLabel = (o: MinOption) => <VisitModality modality={o.value} />
const getOptionLabelProps = (_: MinOption) => ({ className: 'bg-white' })

type VisitModalityFormProps = {
  memberId: string
  pendingVisit: PendingVisit
  addPhoneNumber: (phone: Phone) => void
  initialValues: FormValues
  schema: AnyObjectSchema
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleSubmit: FormEventHandler<HTMLFormElement>
  values: FormValues
  errors: FormikErrors<FormValues>
  enableReinitialize: boolean
  dirty: boolean
  isValid: boolean
  isLoading: boolean
  children: ({ disabled }: { disabled: boolean }) => JSX.Element
}

const VisitModalityForm = (props: VisitModalityFormProps) => {
  const { handleSubmit, setFieldValue, addPhoneNumber, values, errors, isValid, schema, memberId, pendingVisit } = props
  const phoneDialogRef = useRef<ModalRef>(null)

  const flags = useFlags()

  const radioGroupLabelId = useId()

  const member = useSelector(MemberSelectors.getMember(memberId))

  const successVisit = useMemo(() => {
    return isSuccess(pendingVisit.visitType)
  }, [pendingVisit])

  useEffect(() => {
    if (values.phoneId && member) {
      const phone = member.phones.find(({ id }: { id: string }) => values.phoneId === id)
      if (!isEmpty(phone)) setFieldValue('phone', phone)
    }
  }, [values.phoneId, member, errors, setFieldValue])

  const phoneOptions = useMemo(() => {
    if (!member || isEmpty(member.phones)) return []
    return member.phones.map((phone: Phone) => ({
      value: phone.id,
      label: phoneFormat(phone.number),
    }))
  }, [member])

  useEffect(() => {
    if (phoneOptions.length === 1) {
      const [option] = phoneOptions
      setFieldValue('phoneId', option.value)
    }
  }, [phoneOptions])

  const handleOpenDialog = useCallback(() => {
    phoneDialogRef.current?.open()
  }, [])

  const handleAddPhone = useCallback(
    async (phone: PhoneFormValues) => {
      // @ts-ignore
      const { success, error } = await addPhoneNumber(phone)
      if (success) {
        toast.success(
          <p role="alert" style={{ lineHeight: '18px', margin: '5px' }}>{`Phone number added successfully`}</p>,
        )
        phoneDialogRef.current?.close()
      } else {
        toast.error(
          <p role="alert" className="m-1.5 leading-4 text-neutral-800">
            {error || 'Failed to add phone'}
          </p>,
        )
      }
    },
    [addPhoneNumber],
  )

  const isChatModalityOn = useMemo(() => flags.tcChatModality, [flags])

  const modalityOptions = useMemo(() => {
    // TODO: Improve typing on pending visit and fix visitType and serviceType discrepancy
    // @ts-ignore
    return determineModalityRestrictions(pendingVisit, { [EVisitModalities.chat]: isChatModalityOn })
  }, [isChatModalityOn, pendingVisit])

  const { modality, aslInterpreterNeeded } = values || {}

  const isVideoCall = modality === EVisitModalities.video

  useEffect(() => {
    if (!isVideoCall && aslInterpreterNeeded) {
      setFieldValue('aslInterpreterNeeded', false)
    }
  }, [aslInterpreterNeeded, isVideoCall, setFieldValue])


  return (
    <>
      <form onSubmit={handleSubmit} className="flex w-full flex-1 flex-col">
        <div className="grid max-w-[1210px] sm:grid-cols-[570px] 2xl:grid-cols-[570px_1fr]">
          <div>
            <h2 className="typography-h5 mb-6 text-neutral-800" id={radioGroupLabelId}>
              {`What type of ${successVisit ? 'session' : 'visit'} would you like?*`}
            </h2>
            <div className="mb-10 grid max-w-[435px] grid-cols-1 gap-x-8 gap-y-4">
              <RadioCardGroup
                columns={1}
                aria-labelledby={radioGroupLabelId}
                required={true}
                options={modalityOptions}
                name="modality"
                value={values.modality}
                onChange={(e) => setFieldValue('modality', e.target.value)}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                getLabelProps={getOptionLabelProps}
              />
              {!!isVideoCall && <AslInterpreterCheckboxToggle />}
            </div>
            <FormikRadioGroup
              schema={schema}
              data-testid={TestIds.newVisit.input.phone}
              label={'What is a good number to reach you on?'}
              name={'phoneId'}
              options={phoneOptions}
              className="mb-6"
            />
            <button
              data-testid={TestIds.newVisit.button.addPhone}
              onClick={handleOpenDialog}
              className="btn-small btn-primary-outlined"
              type="button"
            >
              Add a New Number
              <PlusIcon className="text-primary-800 h-[24px] w-[24px]" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden items-center justify-center 2xl:flex ">
            <img src={VisitModalityIllustrationUrl} className="h-auto w-[590px] max-w-full" alt="" />
          </div>
        </div>
        {props.children({ disabled: !isValid })}
      </form>
      <PhoneFormDialog ref={phoneDialogRef} onSubmit={handleAddPhone} />
    </>
  )
}

export default withForm(VisitModalityForm)
