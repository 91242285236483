import MedicalNowIcon from '@ui-components-3/icons/lib/light/UserDoctorMessage'
import TalkNowIcon from '@ui-components-3/icons/lib/light/MessageMedical'
import HCIcon from '@ui-components-3/icons/lib/light/HeartCircleBolt'
import MedicalIcon from '@ui-components-3/icons/lib/light/BriefcaseMedical'
import PsychiatryIcon from '@ui-components-3/icons/lib/light/HeadSideBrain'
import TherapyIcon from '@ui-components-3/icons/lib/light/NotesMedical'
import TrophyIcon from '@ui-components-3/icons/lib/light/Trophy'
import SelfCareIcon from '@ui-components-3/icons/lib/light/HandHoldingHeart'
import CommunityIcon from '@ui-components-3/icons/lib/light/MessageLines'

const VisitTypes = {
  medical: 'medical',
  therapy: 'therapy',
  therapyNow: 'therapy_now',
  medicalNow: 'medical_now',
  psychiatry: 'psychiatry',
  healthCoach: 'hc',
  success: 'success_coaching',
  successNow: 'success_coaching_now',
} as const

export const CareTypes = {
  community: 'community',
  selfCare: 'self_care',
}

export const ServiceStatusTypes = {
  hidden: 'hidden',
  disabled: 'disabled',
  conditionally_enabled: 'conditionally_enabled',
  visible: 'visible',
}

export const ConsentStatuses = {
  pending: 'pending',
  completed: 'completed',
}

export const ConsentTypes = {
  self: 'self',
  parent: 'parental',
}

export type MinorConsentPhase2Type = {
  type: string
  service: ServiceTypes
  status: string
  selfConsentPreApprovalId?: string
}

export type SelfConsentApprovalType = {
  selfConsentConfirmationId: string
  createdAt: string
}

export const VisitTypeValues = Object.values(VisitTypes)

export const routingEnabledServices = [VisitTypes.medical, VisitTypes.medicalNow, VisitTypes.healthCoach]

export const minorSelfConsentServices: ServiceTypes[] = [
  VisitTypes.therapyNow,
  VisitTypes.healthCoach,
  VisitTypes.success,
  VisitTypes.successNow,
]

export type TVisitType = (typeof VisitTypes)[keyof typeof VisitTypes]
export const OnDemandTypes: TVisitType[] = [VisitTypes.medicalNow, VisitTypes.therapyNow, VisitTypes.successNow]
export const ScheduledTypes: TVisitType[] = [
  VisitTypes.medical,
  VisitTypes.therapy,
  VisitTypes.psychiatry,
  VisitTypes.healthCoach,
  VisitTypes.success,
]

export type ServiceTypes = (typeof VisitTypes)[keyof typeof VisitTypes]

export const DisplayVisitType = {
  [VisitTypes.medicalNow]: 'MedicalNow',
  [VisitTypes.therapyNow]: 'TalkNow',
  [VisitTypes.medical]: 'Medical',
  [VisitTypes.therapy]: 'Counseling',
  [VisitTypes.psychiatry]: 'Psychiatry',
  [VisitTypes.healthCoach]: 'Health Coaching',
  [VisitTypes.success]: 'Success Coaching',
  [VisitTypes.successNow]: 'Success Coaching',
  [CareTypes.community]: 'Community',
  [CareTypes.selfCare]: 'Self Care',
  needs: 'Basic Needs',
} as const

export const serviceMap = {
  now: {
    therapy: 'therapy_now',
    medical: 'medical_now',
    success_coaching: 'success_coaching_now',
  },
  scheduled: {
    medical: 'medical',
    therapy: 'therapy',
    psychiatry: 'psychiatry',
    hc: 'hc',
    success_coaching: 'success_coaching',
  },
}

export const iconForService = (serviceType) => {
  switch (serviceType) {
    case 'medical_now':
      return MedicalNowIcon
    case VisitTypes.therapyNow:
      return TalkNowIcon
    case VisitTypes.medical:
      return MedicalIcon
    case VisitTypes.healthCoach:
      return HCIcon
    case VisitTypes.therapy:
      return TherapyIcon
    case VisitTypes.psychiatry:
      return PsychiatryIcon
    case VisitTypes.success:
    case VisitTypes.successNow:
      return TrophyIcon
    default:
      return null
  }
}

export const iconForRecommendedServiceAndCare = (type) => {
  switch (type) {
    case VisitTypes.therapyNow:
      return TalkNowIcon
    case VisitTypes.therapy:
      return MedicalIcon
    case CareTypes.community:
      return CommunityIcon
    case CareTypes.selfCare:
      return SelfCareIcon
    default:
      return null
  }
}

export const timeFrameForServiceType = (serviceType) => {
  switch (serviceType) {
    case VisitTypes.therapy:
      return '50'
    case VisitTypes.therapyNow:
      return '20-30'
    case VisitTypes.psychiatry:
      return 'Visit length varies'
    case VisitTypes.healthCoach:
      return '25-30'
    case VisitTypes.medicalNow:
    case VisitTypes.medical:
      return '5-15'
    case VisitTypes.success:
    case VisitTypes.successNow:
      return null
    default:
      return 'Visit length varies'
  }
}

export const subtitleForService = (serviceType) => {
  switch (serviceType) {
    case 'medical_now':
      return 'Medical care for urgent needs'
    case VisitTypes.therapyNow:
      return 'Emotional support when you need someone to talk to'
    case VisitTypes.medical:
      return 'Scheduled medical care for common illnesses'
    case VisitTypes.healthCoach:
      return 'Develop and make progress on your health and well-being goals'
    case VisitTypes.therapy:
      return 'Explore challenges and develop coping skills while building a meaningful relationship with a therapist'
    case VisitTypes.psychiatry:
      return 'Psychiatric care and medication support for mental health issues'
    case VisitTypes.success:
      return 'Scheduled guidance on academic and personal goals'
    case VisitTypes.successNow:
      return 'Guidance on pressing academic and personal goals'
    case CareTypes.community:
      return 'Discuss topics with and get support from your peers'
    case CareTypes.selfCare:
      return 'Elevate your well-being, on your own, one step at a time'
    default:
      return null
  }
}

export const orderForService = (serviceType: ServiceTypes) => {
  switch (serviceType) {
    case VisitTypes.therapyNow:
      return 0
    case VisitTypes.medicalNow:
      return 1
    case VisitTypes.successNow:
      return 2
    case VisitTypes.healthCoach:
      return 3
    case VisitTypes.success:
      return 4
    case VisitTypes.therapy:
      return 5
    case VisitTypes.medical:
      return 6
    case VisitTypes.psychiatry:
      return 7
    default:
      return null
  }
}

export const medicalAndHCServices = [VisitTypes.medical, VisitTypes.healthCoach, VisitTypes.medicalNow]

const ServiceTypes = Object.values(DisplayVisitType)
export const AVAILABLE_SERVICES_COUNT = ServiceTypes.length

export const getActualVisitType = (type, scheduledType) => {
  if (type === VisitTypes.medical && scheduledType === 'now') {
    return VisitTypes.medicalNow
  }
  if (type === VisitTypes.therapy && scheduledType === 'now') {
    return VisitTypes.therapyNow
  }
  return type
}

export const OutsideTheUSVisitTypes = [VisitTypes.therapyNow, VisitTypes.healthCoach]

export const RecommendedScreenVariants = {
  result4: 'result4',
  result5: 'result5',
  result7: 'result7',
} as const

export type RecommendedScreenVariantsType = (typeof RecommendedScreenVariants)[keyof typeof RecommendedScreenVariants]

export type Phone = {
  id: string
  preferred: boolean
  number: string
  type: string
  acceptSms: boolean
}

export const minorConsentPhase2Values = [
  'status',
  'isSelfConsentGranted',
  'isParentConsentGranted',
  'selfConsentPreApprovalId',
  'selfConsentConfirmationId',
]

export default VisitTypes
