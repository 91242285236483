import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Illustration from 'assets/images/ill_visit_recommended.svg'
import RadioCardsGroup from '@ui-components-3/ui/lib/components/RadioCardsGroup'
import useId from '@ui-components-3/ui/lib/hooks/useId'
import {
  VisitCreationImage,
  VisitCreationScene,
} from 'components/visit-creation/VisitCreationWrappers/VisitCreationWrappers'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { ADD_PENDING_VISIT, COMMUNITY, EXPLORE, createWithQuery } from 'routes/paths'
import { Steps } from 'pages/AddPendingVisitPage/AddPendingVisitPage'
import VisitTypes, {
  CareTypes,
  DisplayVisitType,
  OnDemandTypes,
  RecommendedScreenVariants,
  TVisitType,
} from 'util/visit-types'
import ServiceInfo from '../ServiceInfo'
import { MinOption } from 'types/form/Form'
import VisitScheduleType from 'util/visit-schedule-types'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedOptions, selectVariant } from 'redux/selectors/visit-questions'
import * as ServiceSelectors from 'redux/selectors/services'
import { saveCareQuestionnaire } from 'redux/thunks/clients'
import useScheduledVisitsLimit from 'hooks/useScheduledVisitsLimit'

type ServiceOption = MinOption & { disabled: boolean; order: number }

const getOptionServiceCardLabel = (option: ServiceOption) => (
  <ServiceInfo type={option.value} disabled={option.disabled} recommended />
)

const getOptionCardValue = (o: ServiceOption) => o.value
const isOptionCardDisabled = (option: ServiceOption) => option.disabled
const getOptionCardProps = (_: ServiceOption) => ({ className: 'bg-white w-full h-full flex [&>div]:w-full [&>div]:flex-1' })

const ServiceOptions = [VisitTypes.therapyNow, VisitTypes.therapy]

const FormSchema = Yup.object({
  visitType: Yup.string().required('Required'),
  scheduledType: Yup.string(),
  navigateTo: Yup.string(),
})

type FormValuesType = Yup.InferType<typeof FormSchema>

type FormProps = {
  children: RecVisitTypeFormProps['children']
  onSubmit: RecVisitTypeFormProps['onSubmit']
  initalValues: FormValuesType
  handleReturnToDashboard: () => void
}

const Form = ({ children, onSubmit, initalValues, handleReturnToDashboard }: FormProps) => {
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: initalValues,
    resolver: yupResolver(FormSchema),
  })
  const dispatch = useDispatch()
  const variant = useSelector(selectVariant)
  const allServices = useSelector(ServiceSelectors.getVisitServices)
  const answers = useSelector(selectSelectedOptions)

  const checkServiceType = useCallback(
    (serviceType: string) => {
      return allServices.some((service) => service.serviceType === serviceType)
    },
    [allServices],
  )

  const { setScheduledVisitType, LimitRescheduleDialog } = useScheduledVisitsLimit(handleReturnToDashboard)

  const visitType = watch('visitType')

  useEffect(() => {
    if (setScheduledVisitType(visitType as TVisitType)) return
    switch (visitType) {
      case CareTypes.community:
        setValue('navigateTo', COMMUNITY)
        break
      case CareTypes.selfCare:
        setValue('navigateTo', EXPLORE)
        break
      default:
        if (OnDemandTypes.includes(visitType as TVisitType)) {
          setValue('scheduledType', VisitScheduleType.now)
        } else {
          setValue('scheduledType', VisitScheduleType.scheduled)
        }
        setValue('navigateTo', '')
        break
    }
  }, [visitType, setValue])

  const idPrefix = useId()
  const ProviderCareRadioGroupLabelId = idPrefix + 'provider-care'
  const CareOptionsRadioGroupLabelId = idPrefix + 'care-options'

  const services = useMemo(
    () => [
      {
        value: VisitTypes.therapyNow,
        label: DisplayVisitType[VisitTypes.therapyNow],
        disabled: false,
        order: 0,
      },
      {
        value: VisitTypes.therapy,
        label: DisplayVisitType[VisitTypes.therapy],
        disabled: false,
        order: 1,
      },
    ],
    [],
  )

  const careSectionData = useMemo(
    () => [
      {
        value: CareTypes.community,
        label: 'Community',
        disabled: false,
        order: 0,
      },
      {
        value: CareTypes.selfCare,
        label: 'Self Care',
        disabled: false,
        order: 1,
      },
    ],
    [],
  )

  const filteredServices = useMemo(() => {
    const filtered = services.filter((service) => checkServiceType(service.value))
    switch (variant) {
      case RecommendedScreenVariants.result4:
        return filtered
      case RecommendedScreenVariants.result5:
        return filtered.filter((service) => service.value === VisitTypes.therapyNow)
      case RecommendedScreenVariants.result7:
        return filtered.reverse()
      default:
        return services
    }
  }, [services, variant, checkServiceType])

  const onFormSubmit = useCallback(
    async (values: FormValuesType) => {
      const visitTypeToSave = visitType === VisitTypes.therapyNow ? VisitTypes.therapy : visitType
      const newValues = { ...values, visitType: visitTypeToSave }
      const isValidServiceOption = ServiceOptions.includes(visitType as (typeof ServiceOptions)[number])

      if (isValidServiceOption) {
        await dispatch(saveCareQuestionnaire(visitType, answers, () => onSubmit(newValues)))
      } else {
        onSubmit(newValues)
      }
    },
    [answers, dispatch, visitType, onSubmit],
  )

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} className="w-full flex-shrink-0 md:w-2/4">
      <h2 className="typography-h5 mb-2 text-neutral-800">Recommended Services for You</h2>
      <p className="typography-body mb-9 font-medium text-neutral-700">
        Recommendations are based on your responses. Select a service to get started.
      </p>
      <div className="flex max-w-[600px] flex-col gap-9 pb-10">
        <div>
          <h3 className="typography-caption mb-2 text-neutral-500" id={ProviderCareRadioGroupLabelId}>
            provider care
          </h3>
          <Controller
            control={control}
            name="visitType"
            render={({ field }) => (
              <RadioCardsGroup
                aria-labelledby={ProviderCareRadioGroupLabelId}
                required={true}
                columns={1}
                name="visitType"
                value={field.value}
                options={filteredServices}
                getOptionLabel={getOptionServiceCardLabel}
                getOptionValue={getOptionCardValue}
                getLabelProps={getOptionCardProps}
                isOptionDisabled={isOptionCardDisabled}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </div>
        <div>
          <h3 className="typography-caption mb-2 text-neutral-500" id={CareOptionsRadioGroupLabelId}>
            Additional Care Options
          </h3>
          <Controller
            control={control}
            name="visitType"
            render={({ field }) => (
              <RadioCardsGroup
                aria-labelledby={CareOptionsRadioGroupLabelId}
                required={true}
                columns={1}
                name="visitType"
                value={field.value}
                options={careSectionData}
                getOptionLabel={getOptionServiceCardLabel}
                getOptionValue={getOptionCardValue}
                getLabelProps={getOptionCardProps}
                isOptionDisabled={isOptionCardDisabled}
                onChange={(e) => field.onChange(e)}
              />
            )}
          />
        </div>
        <Link
          to={createWithQuery(ADD_PENDING_VISIT, { step: Steps.visitType, throughStep: Steps.recommended })}
          className="typography-body-m text-primary-600 w-fit self-center font-medium underline"
          aria-label="Click here to navigate to the help center"
        >
          View all services
        </Link>
      </div>
      {children({ disabled: !isValid })}
      {LimitRescheduleDialog}
    </form>
  )
}

type RecVisitTypeFormProps = {
  children: (values: { disabled: boolean }) => ReactNode
  onSubmit: (values: FormValuesType) => void
  handleReturnToDashboard: () => void
}

const RecommendedVisitTypeForm = ({ children, onSubmit, ...props }: RecVisitTypeFormProps) => {
  const initalValues = {
    visitType: '',
    scheduledType: '',
    navigateTo: '',
  }

  return (
    <VisitCreationScene>
      <Form {...props} onSubmit={onSubmit} initalValues={initalValues}>
        {children}
      </Form>
      <VisitCreationImage>
        <img className="hidden h-full w-full md:inline-block " src={Illustration} alt="" />
      </VisitCreationImage>
    </VisitCreationScene>
  )
}

export default RecommendedVisitTypeForm
