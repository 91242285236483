import Component from './component'
import { Journey } from './journey'

export type SelfCareContent = Journey | Component

export type RandomizedSelfCareContent = Array<SelfCareContent>

export type SelfCaresResponse = {
  randomized?: RandomizedSelfCareContent
}

export const TIMELY_CAST_JOURNEY_TITLE = 'TimelyCast'

export const SELF_CARE_SERVICE_LINE_TYPES = {
  studentSelfCare: 'StudentSelfCare',
  studentSuccessCourses: 'StudentSuccessCourses',
} as const

export type SelfCareServiceLineType = (typeof SELF_CARE_SERVICE_LINE_TYPES)[keyof typeof SELF_CARE_SERVICE_LINE_TYPES]
