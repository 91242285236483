import { ReactionTypes } from 'types/community/postReaction'

const TestIds = {
  home: {
    img: {
      need: 'home-needs---img',
    },
    view: {
      queue: 'home-queue--view',
      pendingVisit: (index: string | number) => `home-pending-visit-${index}--view`,
      cancelVisit: (index: string | number) => `home-cancel-pending-visit-${index}--view`,
      cancelVisitActions: (index: string | number) => `home-cancel-pending-visit-actions-${index}--view`,
      needs: 'home-basic-needs--view',
      upcomingVisit: (index: string | number) => `home-upcoming-visit-${index}--view`,
      cancelVisitSuggestion: (index: string | number) => `home-cancel-visit-suggestion-${index}--view`,
      cancelVisitSuggestionActions: (index: string | number) => `home-cancel-visit-suggestion-actions-${index}--view`,
    },
    label: {
      queueTitle: 'home-queue-title--label',
      queueInfo1: 'home-queue-info1--label',
      queueInfo2: 'home-queue-info2--label',
      pendingVisitTitle: (index: string | number) => `home-pending-visit-title-${index}--label`,
      pendingVisitProviderName: (index: string | number) => `home-pending-visit-prov-name-${index}--label`,
      pendingVisitProviderSpecialty: (index: string | number) => `home-pending-visit-prov-spec-${index}--label`,
      pendingVisitSubtitle: (index: string | number) => `home-pending-visit-subtitle-${index}--label`,
      pendingVisitReason: (index: string | number) => `home-pending-visit-reason-${index}--label`,
      pendingVisitDate: (index: any, key: any) => `home-pending-visit-date-${index}-${key}--label`,
      pendingVisitTime: (index: any, key: any) => `home-pending-visit-time-${index}-${key}--label`,
      cancelVisitTitle: 'home-cancel-visit-title--label',
      needTitle: 'home-needs-title--label',
      needSubtitle: 'home-needs-subtitle--label',
      needDialogTitle: 'home-needs-dialog-title--label',
      upcomingVisitTitle: (index: string | number) => `home-upcoming-visit-title-${index}--label`,
      upcomingVisitDay: (index: string | number) => `home-upcoming-visit-day-${index}--label`,
      upcomingVisitDate: (index: string | number) => `home-upcoming-visit-date-${index}--label`,
      upcomingVisitYear: (index: string | number) => `home-upcoming-visit-year-${index}--label`,
      upcomingVisitTimeTitle: (index: string | number) => `home-upcoming-visit-time-title-${index}--label`,
      upcomingVisitTime: (index: string | number) => `home-upcoming-visit-time-${index}--label`,
      upcomingVisitTimeSystem: (index: string | number) => `home-upcoming-visit-time-system-${index}--label`,
      upcomingVisitReasonTitle: (index: string | number) => `home-upcoming-visit-reason-title-${index}--label`,
      upcomingVisitReason: (index: string | number) => `home-upcoming-visit-reason-${index}--label`,
      upcomingVisitProviderName: (index: string | number) => `home-upcoming-visit-provider-name-${index}--label`,
      upcomingVisitProviderSpec: (index: string | number) => `home-upcoming-visit-provider-specialty-${index}--label`,
      schedulingSubtitle: (index: string | number) => `home-new-times-subtitle-${index}--label`,
      schedulingReasonTitle: (index: string | number) => `home-new-times-reason-title-${index}--label`,
      schedulingReason: (index: string | number) => `home-new-times-reason-${index}--label`,
      schedulingSuggestionsTitle: (index: string | number) => `home-new-times-suggestion-title-${index}--label`,
      schedulingDate: (index: string | number, key: string) => `home-new-times-date-${index}-${key}--label`,
      schedulingTime: (index: string | number, key: string) => `home-new-times-time-${index}-${key}--label`,
    },
    button: {
      cancelVisit: (index: string | number) => `home-cancel-visit-${index}--button`,
      visitInfo: (index: string | number) => `home-visit-info-${index}--button`,
      joinVisit: (index: string | number) => `home-join-visit-${index}--button`,
      addPhotos: (index: string | number) => `home-add-photos-${index}--button`,
      confirmVisit: (index: string | number) => `home-confirm-visit-${index}--button`,
      cancelVisitSuggestion: (index: string | number) => `home-cancel-visit-suggestion-${index}--button`,
    },
  },
  navigation: {
    view: {
      menu: 'navigation-menu--view',
      account: 'navigation-account--view',
    },
    button: {
      menuItem: (index: string | number, label: string) => `navigation-item-${label}-${index}--button`,
      avatar: 'navigation-account-avatar--button',
      subAvatar: 'navigation-account-sub-avatar--button',
      account: 'navigation-account--button',
      logout: 'navigation-logout--button',
    },
    label: {
      accountEmail: 'navigation-account-email--label',
      account: 'navigation-account--label',
      logout: 'navigation-logout--label',
      breadcrumbs: (item: any) => `navigation-breadcrumbs--label-${item}`,
    },
  },
  login: {
    input: {
      email: 'login-email--input',
      password: 'login-password--input',
      code: 'login-code--input',
      generateMfa: 'login-generate-mfa--input',
      verifyMfa: 'login-verify-mfa--input',
      verifyCreateAccountEmail: 'login-verify-create-account-email--input',
      verifyCreateAccountDob: 'login-verify-create-account-dob--input',
    },
    button: {
      continue: 'login-continue--button',
      login: 'login--button',
      verifyCode: 'login-verify-code--button',
      cancel: 'login-cancel--button',
      forgot: 'forgot-password--button',
      downloadApple: 'download-apple--link',
      downloadGoogle: 'download-google--link',
      termsAndConditions: 'terms-conditions--link',
      privacyPolicy: 'privacy-policy--link',
      logo: 'logo--button',
      togglePasswordVisibility: 'password-visibility--icon',
      forgotPasswordCancel: 'forgot-password-cancel--button',
      forgotPasswordOk: 'forgot-password-ok--button',
      timelyMd: 'timely-md--link',
      resendMfa: 'login-resend-mfa--button',
      reselectMfa: 'login-reselect-mfa--button',
    },
    label: {
      copyright: 'copyright--label',
      forgotPasswordTitle: 'forgot-password-title--label',
      sso: 'sso-redirect-title',
    },
    view: {
      email: 'login-email--view',
      password: 'login-password--view',
      sso: 'login-sso--view',
      accessCode: 'login-access-code--view',
      loading: 'loading-login--view',
      forgotPassword: 'forgot-password--view',
      expiredPassword: 'login-expired-password--view',
      verifyCreateAccount: 'login-verify-create-account--view',
    },
    form: {
      accessCode: 'login-access-code--form',
      generateMfa: 'login-generate-mfa--form',
      verifyMfa: 'login-verify-mfa--form',
      verifyCreateAccount: 'login-verify-create-account--form',
    },
  },
  security: {
    input: {
      password: 'new-password--input',
      oldPassword: 'old-password--input',
      confirmPassword: 'confirm-password--input',
    },
    button: {
      continue: 'update-password--btn',
      cancel: 'cancel-update-password--btn',
      toggleOldPasswordVisibility: 'toggle-old-password--btn',
      toggleNewPasswordVisibility: 'toggle-new-password--btn',
      toggleConfirmPasswordVisibility: 'toggle-confirm-password--btn',
    },
  },
  survey: {
    label: {
      name: 'survey-name--label',
      description: 'survey-description--label',
      dialogTitle: 'survey-dialog-title--label',
      dialogCloseId: 'survey-dialog-close',
      questionPrompt: (index: string | number) => `survey-question-prompt-${index}--label`,
    },
    view: {
      card: 'survey-card--view',
    },
    img: {
      main: 'survey-main--img',
    },
    button: {
      skip: 'survey-skip--button',
      submit: 'survey-submit--button',
      takeSurvey: 'survey-take-survey--button',
    },
    input: {
      answer: (index: string | number) => `survey-answer-${index}--input`,
    },
  },
  account: {
    navigationTab: 'navigation-item-Account-4--button',
    view: {
      general: 'account-general-view',
      navigation: 'account-navigation-view',
      generalPage: 'account-general-page-view',
      generalContainer: 'account-general-container-view',
      dependentComponent: 'account-dependent-view',
      service: 'account-service-view',
      serviceCard: (type: string) => `account-service-card-${type}--view`,
      incompleteMedicalInfo: 'account-incomplete-medical-info--view',
    },
    button: {
      incompleteMedicalInfoIcon: 'account-incomplete-medical-info-icon--button',
      completeMedicalInfo: 'account-complete-medical-info--button',
      dependentComponent: (id: any) => `account-dependent-button--${id}`,
      editGeneral: 'edit-general--button',
      save: 'account-save--button',
      addDependent: 'add-dependent--button',
      addCard: 'add-card--button',
      addService: 'add-service--button',
      editDependent: (i: string | number) => `edit-dependent-${i}--button`,
      changePassword: 'account-change-password--button',
      verifyCode: 'account-verify-service-code--button',
      cancelCode: 'account-cancel-service-code--button',
      cancelCard: 'account-cancel-card--button',
      navigationItem: (link: any) => `account-navigation-item-${link}--button`,
    },
    input: {
      paymentMethod: (index: string | number) => `edit-payment-method-${index}--item`,
      serviceCode: 'account-service-code--input',
    },
    label: {
      incompleteMedicalInfoTitle: 'account-incomplete-medical-info-title--label',
      incompleteMedicalInfoContent: 'account-incomplete-medical-info-content--label',
      navigationItem: (link: string) => `account-navigation-item-${link}--label`,
      navigationListTitle: (title: string) => `account-navigation-list-title-${title}--label`,
      avatar: 'account-avatar--label',
      name: 'account-name--label',
      title: (title: string) => `account-title-${title}--label`,
      incomplete: (title: string) => `account-incomplete-${title}--label`,
      services: (index: string | number) => `account-services-${index}--label`,
      servicesDisabled: 'account-services-disabled--label',
      emailTitle: 'account-email-title--label',
      email: 'account-email--label',
      languageTitle: 'account-language-title--label',
      language: 'account-language--label',
      genderTitle: 'account-gender-title--label',
      gender: 'account-gender--label',
      genderIdentityTitle: 'account-gender-identity-title--label',
      preferredNameTitle: 'account-preferred-name-title--label',
      preferredName: 'account-preferred-name--label',
      genderIdentity: 'account-gender-identity--label',
      pronounTitle: 'account-pronoun-title--label',
      pronoun: 'account-pronoun--label',
      graduationDateTitle: 'account-graduation-date-title--label',
      graduationDate: 'account-graduation-date--label',
      ethnicityTitle: 'account-ethnicity-title--label',
      ethnicity: 'account-ethnicity--label',
      addressTitle: 'account-address-title--label',
      address: 'account-address--label',
      phoneTitle: 'account-phone-title--label',
      phone: 'account-phone--label',
      phoneTypeTitle: 'account-phone-type-title--label',
      phoneType: 'account-phone-type--label',
      smsTitle: 'account-sms-title--label',
      sms: 'account-sms--label',
      securityTitle: 'account-security-title--label',
      noCards: 'account-no-cards--label',
      serviceTitle: 'account-add-service-title--label',
      expiresIn: (index: string | number) => `account-card-expires-in-${index}--label`,
      endsIn: (index: string | number) => `account-card-ends-in-${index}--label`,
      updatePswdTitle: 'account-update-pswd-title--label',
      updatePswdContent: 'account-update-pswd-content--label',
    },
  },
  dependent: {
    input: {
      dob: 'dependent-dob--input',
      firstName: 'dependent-firstName--input',
      lastName: 'dependent-lastName--input',
      firstNamePreferred: 'dependent-firstNamePreferred-input',
      relation: 'dependent-relation--input',
      preferredLang: 'dependent-preferredLang--input',
      isMemberAddress: 'dependent-isMemberAddress--input',
    },
    button: {
      save: 'dependent-save--button',
      cancel: 'dependent-cancel--button',
    },
  },
  message: {
    visit: (id: string | number) => `message-visit--${id}`,
    task: (id: string | number) => `message-task--${id}`,
    button: {
      downloadCare: 'download-care--button',
      archive: 'archive-message--button',
    },
    selectItem: (id: any) => `select-item-message--${id}`,
    selectLabel: (id: string) => `select-item-label--${id}`,
    navigation: 'navigation-item-Messages-3--button',
  },
  registration: {
    view: {
      navigation: 'navigation--view',
      memberInfo: 'member-info--view',
      password: 'password--view',
      loading: 'loading--view',
    },
  },
  eligibilityRegistration: {
    view: {
      eligibility: 'eligibility--form',
      accessCode: 'access-code--container',
      accountFound: 'account-found--modal',
      accountFoundBtns: 'account-found--btns--container',
    },
    input: {
      email: 'eligibility-email--input',
      firstName: 'eligibility-firstName--input',
      lastName: 'eligibility-lastName--input',
      dob: 'eligibility-dob--input',
      logo: 'eligibility-logo--img',
    },
    label: {
      title: 'eligibility-title--label',
    },
    button: {
      continue: 'eligibility-continue--button',
    },
  },
  accountRegistration: {
    input: {
      password: 'account-password--input',
      confirmPassword: 'account-confirm-password--input',
    },
    button: {
      continue: 'account-continue--button',
      toogleVisibility: 'account-toggle-password--button',
    },
  },
  memberRegistration: {
    input: {
      landuage: 'member-language--input',
      ethnicity: 'member-ethnicity--input',
      gender: 'member-gender--input',
      selfSelect: 'member-self-select--input',
      language: 'member-language--input',
      street1: 'member-street1--input',
      street2: 'member-street2--input',
      city: 'member-city--input',
      state: 'member-state--input',
      zip: 'member-zip--input',
      phone: 'member-phone--input',
      phoneType: 'member-phoneType--input',
      acceptSms: 'member-acceptSMS--input',
      acceptTerms: 'member-acceptTerms--input',
    },
    button: {
      continue: 'member-continue--button',
      acceptTerms: 'member-accept-terms--link',
    },
    label: {
      smsIntructions: 'sms-instructions--label',
    },
  },
  dashboard: {
    button: {
      startVisit: 'dashboard-start-visit--button',
      community: 'dashboard-community--button',
    },
    view: {
      loading: 'dashboard-loading--view',
    },
    exploreCard: {
      view: 'dashboard-explore-card--view',
    },
    newServiceModal: {
      button: 'dashboard-new-service-modal--button',
      view: 'dashboard-new-service-modal--view',
    },
    visit: {
      assessment: {
        button: 'dashboard-visit-assessment-entry--button',
        emergencyWarning: 'dashboard-visit-assessment-emergency-warning--view',
        entry: {
          view: 'dashboard-visit-assessment-entry-modal--view',
          cancel: 'dashboard-visit-assessment-entry-modal-cancel--button',
          start: 'dashboard-visit-assessment-entry-modal-start--button',
        },
        form: {
          view: 'dashboard-visit-assessment-form-modal--view',
        },
        success: {
          view: 'dashboard-visit-assessment-success-modal--view',
          continue: 'dashboard-visit-assessment-entry-modal-continue--button',
        },
        explanationModal: {
          button: 'dashboard-visit-assessment-explanation-modal-entry--button',
          view: 'dashboard-visit-assessment-explanation-modal--view',
        },
      },
      card: {
        view: (type: string, id: string) => `dashboard-visit-card-${type}-${id}--view`,
      },
    },
  },
  medicalWizard: {
    view: {
      container: 'medical-wizard-container--view',
      init: 'medical-wizard-init',
      navigation: 'medical-wizard-navigation--view',
      familyHistoryForm: 'medical-wizard-family-history-form--view',
      familyHistoryList: 'medical-wizard-family-history-list--view',
      medicationsForm: 'medical-wizard-medications-form--view',
      medicationsList: 'medical-wizard-medications-list--view',
      allergiesForm: 'medical-wizard-allergies-form--view',
      allergiesList: 'medical-wizard-allergies-list--view',
      primaryCareForm: 'medical-wizard-primaryCare-form--view',
      primaryCareList: 'medical-wizard-primaryCare-list--view',
    },
    button: {
      start: 'medical-wizard-init-start',
      later: 'medical-wizard-init-later',
      back: 'medical-wizard-back--button',
      skip: 'medical-wizard-skip--button',
    },
    label: {
      title: 'medical-wizard-title--label',
      subtitle: 'medical-wizard-subtitle--label',
      instructions: 'medical-wizard-instructions--label',
      footer: 'medical-wizard-footer--label',
      basicInfoTitle: 'medical-wizard-basic-info-title--label',
      getStartedTitle: 'medical-wizard-get-started-title--label',
      profileComplete: 'medical-wizard-profile-complete-title--label',
      lifestyleTitle: 'medical-wizard-lifestyle-title--label',
      medicalHistoryTitle: 'medical-wizard-medical-title--label',
      familyHistoryTitle: 'medical-wizard-family-title--label',
      medicationsTitle: 'medical-wizard-medications-title--label',
      allergiesTitle: 'medical-wizard-allergies-title--label',
      primaryCareTitle: 'medical-wizard-primaryCare-title--label',
    },
    input: {
      logo: 'medical-wizard-logo--input',
    },
    dialog: {
      removeItem: {
        view: 'medical-wizard-remove-item-modal--view',
        confirm: 'medical-wizard-remove-item-modal-confirm--button',
        cancel: 'medical-wizard-remove-item-modal-cancel--button',
      },
    },
  },
  forms: {
    accountService: {
      view: {
        form: 'account-service-dialog-form--view',
      },
    },
    accountSendFeedbackDialog: {
      view: {
        form: 'account-send-feedback-dialog-view',
      },
      button: {
        submit: 'account-send-feedback-dialog-submit',
      },
      input: {
        textField: 'account-send-feedback-dialog-text-field',
      },
    },
    accountSecurity: {
      view: {
        form: 'account-security-form--view',
      },
    },
    medicalGeneral: {
      button: {
        submit: 'medical-general-submit',
        cancel: 'medical-general-cancel',
      },
      input: {
        feet: 'medical-general-height-feet',
        inches: 'medical-general-height-inches',
        weight: 'medical-general-weight',
      },
      label: {
        height: 'medical-general-height-label',
        weight: 'medical-general-weight-label',
      },
      view: {
        form: 'medical-general-form--view',
      },
    },
    lifestyle: {
      button: {
        submit: 'medical-lifestyle-submit',
        cancel: 'medical-lifestyle-cancel',
      },
      input: {
        smoke: 'medical-lifestyle-smoke',
        vape: 'medical-lifestyle-vape',
        drink: 'medical-lifestyle-drink',
        sexually_active: 'medical-lifestyle-sexually-active',
        drinkFrequency: 'medical-lifestyle-drink-frequency',
      },
    },
    familyHistory: {
      button: {
        add: 'medical-family-add',
        optOut: 'medical-family-optout',
        next: 'medical-family-next',
        item: (index: string | number) => `medical-family-member-item-${index}--button`,
        editItem: (index: string | number) => `medical-family-member-edit-item-${index}--button`,
      },
      label: {
        noItems: 'medical-family-no-items--label',
        addTitle: 'medical-family-add-member-title--label',
        title: (index: string | number) => `medical-family-member-title-${index}--label`,
        subtitle: (index: string | number) => `medical-family-member-subtitle-${index}--label`,
        content: (index: string | number) => `medical-family-member-content-${index}--label`,
      },
    },
    medications: {
      view: 'medical-medication-form--view',
      button: {
        add: 'medications-add--button',
        optOut: 'medications-optout--button',
        next: 'medications-next--button',
        item: (index: string | number) => `medications-item-${index}--button`,
        editItem: (index: string | number) => `medications-edit-item-${index}--button`,
      },
      label: {
        noItems: 'medications-no-items--label',
        addTitle: 'medications-add-title--label',
        title: (index: string | number) => `medications-title-${index}--label`,
        subtitle: (index: string | number) => `medications-subtitle-${index}--label`,
        content: (index: string | number) => `medications-content-${index}--label`,
      },
    },
    allergies: {
      button: {
        add: 'allergies-add--button',
        optOut: 'allergies-optout--button',
        next: 'allergies-next--button',
        item: (index: string | number) => `allergies-item-${index}--button`,
        editItem: (index: string | number) => `allergies-edit-item-${index}--button`,
      },
      label: {
        noItems: 'allergies-no-items--label',
        addTitle: 'allergies-add-title--label',
        title: (index: string | number) => `allergies-title-${index}--label`,
        subtitle: (index: string | number) => `allergies-subtitle-${index}--label`,
        content: (index: string | number) => `allergies-content-${index}--label`,
      },
    },
    primaryCare: {
      button: {
        add: 'primaryCare-add--button',
        optOut: 'primaryCare-optout--button',
        next: 'primaryCare-next--button',
        item: (index: string | number) => `primaryCare-item-${index}--button`,
        editItem: (index: string | number) => `primaryCare-edit-item-${index}--button`,
      },
      label: {
        noItems: 'primaryCare-no-items--label',
        addTitle: 'primaryCare-add-title--label',
        title: (index: string | number) => `primaryCare-title-${index}--label`,
        subtitle: (index: string | number) => `primaryCare-subtitle-${index}--label`,
        content: (index: string | number) => `primaryCare-content-${index}--label`,
        general: 'primaryCare-general-info--label',
        contact: 'primaryCare-contact-info--label',
      },
    },
    generalAccount: {
      view: {
        accountGeneralForm: 'form-account-general-view',
      },
      label: {
        address: 'form-account-general-address--label',
        phone: 'form-account-general-phone--label',
        basic: 'form-account-general-basic--label',
      },
      input: {
        email: 'form-account-general-email--input',
        language: 'form-account-general-language--input',
        ethnicity: 'form-account-general-ethnicity--input',
        gender: 'form-account-general-gender--input',
      },
      button: {
        cancel: 'form-account-general-cancel--button',
        save: 'form-account-general-save--button',
      },
    },
    address: {
      input: {
        street1: 'form-address-street1--input',
        street2: 'form-address-street2--input',
        city: 'form-address-city--input',
        state: 'form-address-state--input',
        zip: 'form-address-zip--input',
      },
    },
    phone: {
      input: {
        number: 'form-phone-number--input',
        type: 'form-phone-type--input',
        sms: 'form-phone-sms--input',
      },
      label: {
        smsInstructions: 'form-phone-sms-instructions--label',
      },
    },
  },
  medical: {
    view: {
      formDialog: 'medical-form-dialog--view',
      loading: 'medical-loading--view',
      removePhoto: (id: string) => `medical-remove-photo-${id}--view`,
      removePhotoActions: (id: string) => `medical-remove-photo-buttons-${id}--view`,
    },
    button: {
      medicalDialogCancel: 'medical-dialog-cancel--button',
      medicalDialogAdd: 'medical-dialog-add--button',
      editBasic: 'basic-edit--button',
      editLifestyle: 'lifestyle-edit--button',
      editConditions: 'conditions-edit--button',
      addFamilyHistory: 'family-history-create--button',
      addMedication: 'medication-create--button',
      addAllergy: 'allergy-create--button',
      addPrimaryCare: 'primary-care-create--button',
      editFamilyHistory: (index: string | number) => `family-history-edit-${index}--button`,
      editMedication: (index: string | number) => `medication-edit-${index}--button`,
      editAllergy: (index: string | number) => `allergy-edit-${index}--button`,
      editPrimaryCare: (index: string | number) => `provider-edit-${index}--button`,
      deleteImage: (index: string | number) => `image-delete-${index}--button`,
      viewImage: (index: string | number) => `image-view-${index}--button`,
      complete: (type: string, isComplete: any) => `${type}-${isComplete ? 'complete' : 'incomplete'}--button`,
      removeMedication: (index: string | number) => `medication-remove-${index}--button`,
    },
    img: {
      photo: (index: string | number) => `photo-${index}--img`,
    },
    label: {
      name: 'medical-name--label',
      email: 'medical-email--label',
      dob: 'medical-dob--label',
      height: 'medical-height--label',
      weight: 'medical-weight--label',
      gender: 'medical-gender--label',
      genderIdentity: 'medical-gender-identity--label',
      dobContent: 'medical-dob-content--label',
      heightContent: 'medical-height-content--label',
      weightContent: 'medical-weight-content--label',
      genderContent: 'medical-gender-content--label',
      genderIdentityContent: 'medical-gender-identity-content--label',
      smoke: 'medical-smoke--label',
      smokeContent: 'medical-smoke-content--label',
      vape: 'medical-vape--label',
      vapeContent: 'medical-vape-content--label',
      drink: 'medical-drink--label',
      drinkContent: 'medical-drink-content--label',
      sex: 'medical-sex--label',
      sexContent: 'medical-sex-content--label',
      medConditions: 'medical-conditions--label',
      medConditionsContent: 'medical-conditions-content--label',
      detailTitle: (index: string | number) => `medical-detail-title-${index}--label`,
      detailSubtitle: (index: string | number) => `medical-detail-subtitle-${index}--label`,
      detailContent: (index: string | number) => `medical-detail-content-${index}--label`,
    },
  },
  allergy: {
    input: {
      control: {
        type: 'allergy-type-control--input',
        description: 'allergy-description-control--input',
        reaction: 'allergy-reaction-control--input',
        current: 'allergy-current-control--input',
      },
      type: 'allergy-type--input',
      description: 'allergy-description--input',
      reaction: 'allergy-reaction--input',
      current: 'allergy-current--input',
    },
    button: {
      remove: 'allergy-remove--button',
      save: 'allergy-save--button',
      cancel: 'allergy-cancel--button',
      cancelRemove: 'allergy-cancel-remove-button',
      confirmRemove: 'allergy-confirm-remove-button',
    },
  },
  medication: {
    view: 'medical-medication--view',
    input: {
      control: {
        name: 'medication-name-control--input',
        frequency: 'medication-frequency-control--input',
        dosage: 'medication-dosage-control--input',
        strength: 'medication-strength-control--input',
        strengthAndUnit: 'medication-strength-and-unit-control--input',
        unit: 'medication-unit-control--input',
        unitStrength: 'medication-unitStrength-control--input',
        active: 'medication-active-control--input',
        direction: 'medication-direction-control--input',
        status: 'medication-status-control--input',
        discontinuedReason: 'medication-discontinued-reason-control--input',
      },
      name: 'medication-name--input',
      frequency: 'medication-frequency--input',
      dosage: 'medication-dosage--input',
      strength: 'medication-strength--input',
      unit: 'medication-unit--input',
      unitStrength: 'medication-unitStrength--input',
      active: 'medication-active--input',
    },
    button: {
      remove: 'medication-remove--button',
      save: 'medication-save--button',
      cancel: 'medication-cancel--button',
      cancelRemove: 'medication-cancel-remove--button',
      confirmRemove: 'medication-confirm-remove--button',
    },
  },
  conditions: {
    input: {
      other: 'conditions-other--input',
      condition: (index: string | number) => `condition-${index}--item`,
      conditionType: (index: string | number, typeIndex: string) => `condition-${index}-${typeIndex}--item`,
      checkCondition: (index: string | number) => `condition-${index}--checkbox`,
      checkType: (index: string | number, typeIndex: string) => `condition-${index}-${typeIndex}--checkbox`,
    },
    label: {
      condition: (index: string | number) => `condition-checkbox-${index}--label`,
      type: (index: string | number, typeIndex: string) => `condition-checkbox-${index}-${typeIndex}--label`,
    },
    view: {
      conditionList: 'conditions-list--view',
      otherConditionList: 'conditions-other-list--view',
      conditionTypes: (index: string | number) => `condition-types-${index}--view`,
      otherCondition: (index: string | number) => `other-condition-${index}--container`,
    },
    button: {
      save: 'conditions-save--button',
      cancel: 'conditions-cancel--button',
      toggleCondition: (index: string | number) => `condition-toggle-${index}--button`,
      addOther: 'add-other-condition--button',
      removeCondition: (index: string | number) => `remove-other-condition-${index}--button`,
    },
  },
  familyHistory: {
    input: {
      relationship: 'family-history-relationship--input',
      condition: (index: string | number) => `family-history-condition-${index}--item`,
      conditionType: (index: any, typeIndex: any) => `family-history-condition-${index}-${typeIndex}--item`,
      check: (index: string | number) => `family-history-condition-${index}--checkbox`,
      checkType: (index: any, typeIndex: any) => `family-history-condition-${index}-${typeIndex}--checkbox`,
    },
    button: {
      save: 'family-history-save--button',
      cancel: 'family-history-cancel--button',
      remove: 'family-history-remove--button',
      cancelRemove: 'family-history-cancel-remove--button',
      confirmRemove: 'family-history-confirm-remove--button',
    },
    label: {
      condition: (index: string | number) => `family-history-condition-${index}--label`,
      type: (index: string | number, typeIndex: any) => `family-history-condition-${index}-${typeIndex}--label`,
    },
  },
  primaryCare: {
    input: {
      control: {
        firstName: 'primary-care-firstName-control--input',
        lastName: 'primary-care-lastName-control--input',
        type: 'primary-care-type-control--input',
        specialty: 'primary-care-specialty-control--input',
        suffix: 'primary-care-suffix-control--input',
        phoneNumber: 'primary-care-phoneNumber-control--input',
        faxNumber: 'primary-care-faxNumber-control--input',
        email: 'primary-care-email-control--input',
      },
      firstName: 'primary-care-firstName--input',
      lastName: 'primary-care-lastName--input',
      type: 'primary-care-type--input',
      specialty: 'primary-care-specialty--input',
      suffix: 'primary-care-suffix--input',
      phoneNumber: 'primary-care-phoneNumber--input',
      faxNumber: 'primary-care-faxNumber--input',
      email: 'primary-care-email--input',
    },
    button: {
      remove: 'primary-care-remove--button',
      save: 'primary-care-save--button',
      cancel: 'primary-care-cancel--button',
      cancelRemove: 'primary-care-cancel-remove--button',
      confirmRemove: 'primary-care-confirm-remove--button',
    },
  },
  newVisit: {
    view: {
      addServiceForm: 'new-visit-add-service-form--view',
      memberSelectForm: 'new-visit-member-select-form--view',
      locationForm: 'new-visit-location-form--view',
      locationStateForm: 'new-visit-location-state-form--view',
      locationCountryForm: 'new-visit-location-country-form--view',
      visitTypeForm: 'new-visit-type-form--view',
      phoneForm: 'new-visit-phone-form--view',
      pharmacyList: 'new-visit-pharmacy-list--view',
      pharmacySearchResults: 'new-visit-pharmacy-search-results--view',
      pharmacySearchDialog: 'new-visit-pharmacy-search-dialog--view',
      providerInfo: (index: string | number) => `new-visit-provider-info-${index}--view`,
      cancelVisit: 'new-visit-cancel-dialog--view',
      cancelVisitBtn: 'new-visit-cancel-buttons--view',
      paymentView: 'new-visit-payment--view',
      paymentPayWall: 'new-visit-payment-pay-wall--view',
      paymentForm: 'new-visit-payment-form--view',
      recommendedProviders: 'new-visit-recommended-provider--view',
      availableProviders: 'new-visit-available-provider--view',
    },
    button: {
      next: 'new-visit-next--button',
      cancel: 'new-visit-cancel--button',
      locate: 'new-visit-locate--button',
      removePharmacy: 'new-visit-remove-pharmacy--button',
      addPhone: 'new-visit-add-phone--button',
      viewMap: (index: any) => `new-visit-viewMap-${index}--button`,
      addPharmacy: (index: any) => `new-visit-add-pharmacy-${index}--button`,
      cancelFilter: 'new-visit-cancel-filter--button',
      applyFilter: 'new-visit-apply-filter--button',
      goHome: 'new-visit-go-home--button',
      back: 'new-visit-back--button',
      otherReason: 'new-visit-other-reason--button',
      addNewPharmacy: 'new-visit-add-new-pharmacy--button',
      cancelAddPharmacy: 'new-visit-cancel-add-pharmacy--button',
      searchPharmacy: 'new-visit-search-pharmacy--button',
      selectProvider: (index: string | number) => `new-visit-select-provider-${index}--button`,
      checkProviderIcon: (index: string | number) => `new-visit-check-provider-icon-${index}--button`,
      slotTime: (index: string | number) => `new-visit-slot-time-${index}--button`,
      addCard: 'new-visit-add-card--button',
      addGPay: 'new-visit-add-gpay--button',
    },
    input: {
      acceptSms: 'new-visit-phone-accept-sms--input',
      location: 'new-visit-location--input',
      member: 'new-visit-member--input',
      providerReview: 'new-visit-providerReview--input',
      provider: (index: string | number) => `new-visit-provider-${index}--input`,
      pharmacy: (index: string | number) => `new-visit-pharmacy-${index}--input`,
      pharmacySearch: (index: string | number) => `new-visit-pharmacySearch-${index}--input`,
      service: 'new-visit-service--input',
      reason: 'new-visit-reason--input',
      otherReason: 'new-visit-otherReason--input',
      reasonItem: (index: string | number) => `new-visit-reason-${index}--input`,
      modality: 'new-visit-modality--input',
      aslInterpreterNeeded: 'new-visit-aslInterpreterNeeded--input',
      phone: 'new-visit-phone--input',
      scheduledAt: 'new-visit-scheduledAt--input',
      date: (which: any) => `new-visit-date-${which}--input`,
      time: (which: any) => `new-visit-time-${which}--input`,
      pharmacyName: 'new-visit-pharmacyName--input',
      pharmacyLocation: 'new-visit-pharmacyLocation--input',
      pharmacyCity: 'new-visit-pharmacyCity--input',
      pharmacyState: 'new-visit-pharmacyState--input',
      pharmacyZip: 'new-visit-pharmacyZip--input',
      pharmacySpecialty: 'new-visit-pharmacySpecialty--input',
      pharmacyDistance: 'new-visit-pharmacyDistance--input',
      preferences: {
        gender: 'new-visit-preference-gender--input',
        ethnicity: 'new-visit-preference-ethnicity--input',
        specialty: 'new-visit-preference-specialty--input',
        languages: 'new-visit-preference-languages--input',
      },
      languageFilter: (index: string | number) => `new-visit-filter-language-${index}--input`,
      genderFilter: 'new-visit-filter-gender--input',
      ethnicityFilter: `new-visit-filter-ethnicity-input`,
      specialtyFilter: (index: string | number) => `new-visit-filter-specialty-${index}--input`,
      phoneForm: 'new-visit-phone-form-phone--input',
      phoneType: 'new-visit-phone-type--input',
      card: (index: string | number) => `new-visit-card-${index}--input`,
    },
    label: {
      addServiceTitle: 'new-visit-add-service-title--label',
      pageTitle: 'new-visit-title--label',
      serviceSectionTitle: 'new-visit-service-section-title--label',
      nowSectionTitle: 'new-visit-now-section-title--label',
      scheduleSectionTitle: 'new-visit-schedule-section-title--label',
      serviceTitle: (service: any) => `new-visit-service-${service}-title--label`,
      serviceSubtitle: (service: any) => `new-visit-service-${service}-subtitle--label`,
      serviceItem: (service: any) => `new-visit-service-${service}-item--label`,
      serviceDisabled: 'new-visit-service-disabled--label',
      reasonTitle: 'new-visit-reason-title--label',
      reasonSubtitle: 'new-visit-reason-subtitle--label',
      phoneFormTitle: 'new-visit-phone-form-title--label',
      smsExplanation: 'new-visit-phone-form-sms-explanation--label',
      confirmTitle: 'new-visit-confirm-title--label',
      confirmSubtitle: 'new-visit-confirm-subtitle--label',
      confirmInstructions: 'new-visit-confirm-instructions--label',
      finalInstructions: 'new-visit-final-instructions--label',
      pharmacyTitle: 'new-visit-pharmacy-title--label',
      pharmacySubtitle: 'new-visit-pharmacy-subtitle--label',
      addPharmacyTitle: 'new-visit-add-pharmacy-title--label',
      pharmacyItem: {
        avatar: (item: any) => `new-visit-pharmacy-item-${item}-avatar--label`,
        name: (item: any) => `new-visit-pharmacy-item-${item}-name--label`,
        specialty: (item: any) => `new-visit-pharmacy-item-${item}-specialty--label`,
        street: (item: any) => `new-visit-pharmacy-item-${item}-street--label`,
        city: (item: any) => `new-visit-pharmacy-item-${item}-city--label`,
        phone: (item: any) => `new-visit-pharmacy-item-${item}-phone--label`,
      },
      providerTitle: 'new-visit-select-provider-title--label',
      providerInfo: (index: string | number) => `new-visit-provider-info-item-${index}--label`,
      nextIcon: (index: string | number) => `new-visit-provider-next-icon-item-${index}--label`,
      providerInfoTitle: (index: any, key: any) => `new-visit-provider-info-title-${key}-${index}--label`,
      providerInfoValue: (index: any, key: any) => `new-visit-provider-info-value-${key}-${index}--label`,
      scheduleTimesTitle: 'new-visit-schedule-times-title--label',
      scheduleTimesDescription: 'new-visit-schedule-times-description--label',
      confirmTime: 'new-visit-confirm-time-item--label',
      paymentTitle: 'new-visit-payment-title--label',
      paymentDetail: 'new-visit-payment-detail--label',
      card: (index: string | number) => `new-visit-card-${index}--label`,
    },
  },
  phone: {
    button: {
      save: 'phone-form-save--button',
      cancel: 'phone-form-cancel--button',
    },
  },
  resources: {
    img: {
      main: (key: any) => `resources-main-${key}--img`,
      clinicLogo: 'resources-clinic-logo-img',
    },
    view: {
      card: (key: any) => `resources-card-${key}--view`,
    },
    label: {
      title: (key: any) => `resources-title-${key}--label`,
      content: (key: any) => `resources-content-${key}--label`,
      contentName: (key: any) => `resources-content-name-${key}--label`,
      address: (key: any) => `resources-content-address-${key}--label`,
      phone: (key: any) => `resources-content-phone-${key}--label`,
      day: (key: any, index: string | number) => `resources-content-day-${key}-${index}--label`,
      hours: (key: string, index: string | number) => `resources-content-hours-${key}-${index}--label`,
    },
    button: {
      readMore: (key: any) => `read-more-${key}--button`,
    },
  },
  needs: {
    view: {
      request: 'needs-request--view',
    },
    input: {
      notes: 'needs-notes--input',
      types: 'needs-type-',
    },
    button: {
      complete: 'needs-complete--button',
      getAssistance: 'needs-get-assistance--button',
      cancel: 'needs-cancel--button',
    },
    label: {
      content1: 'needs-saved-dialog-content1--label',
      content2: 'needs-saved-dialog-content2--label',
      content3: 'needs-saved-dialog-content3--label',
      requestTitle: 'needs-request-title--label',
      requestContent1: 'needs-request-content1--label',
      requestContent2: 'needs-request-content2--label',
      requestContent3: 'needs-request-content3--label',
    },
  },
  termsAndConditions: {
    view: 'terms-and-conditions-page--view',
    input: {
      acceptTerms: 'terms-and-conditions-page-accept--input',
    },
    button: {
      back: 'terms-and-conditions-page-back--button',
      continue: 'terms-and-conditions-page-continue--button',
    },
  },
  community: {
    container: {
      view: 'community-container--view',
      loading: {
        view: 'community-container-loading--view',
      },
    },
    guidelines: {
      button: 'community-guidelines--button',
    },
    journey: {
      contentCard: {
        view: (index: number) => `community-journey-content-card-${index}--view`,
        title: 'community-journey-content-card-title--label',
        description: 'community-journey-content-card-description--label',
        image: 'community-journey-content-card-image--view',
        icon: {
          view: 'community-journey-content-card-icon-view--view',
        },
      },
    },
    postDetail: {
      view: 'community-post-detail--view',
      comment: {
        button: 'community-post-detail-comment--button',
        form: {
          replyTag: 'community-post-detail-comment-reply-tag--view',
        },
      },
    },
    postActions: {
      view: 'community-post--actions--view',
      menuView: 'community-post--actions-menu--view',
      button: {
        actions: 'community-page-actions--button',
        delete: 'community-page-actions-delete--button',
        report: 'community-page-actions-report--button',
      },
      report: {
        view: 'community-report-dialog--view',
        button: {
          close: 'community-report-dialog-close--button',
          submit: 'community-report-dialog-submit--button',
        },
      },
    },
    reactions: {
      button: 'community-post-reactions-menu--button',
      view: 'community-post-reactions-menu--view',
      option: (type: string) => `community-post-reactions-${type}-option--button`,
      existingReaction: {
        button: 'community-post-reactions-remove-reaction--button',
      },
      counter: {
        view: 'community-post-reactions-count--view',
        icon: (type: ReactionTypes) => `community-post-reactions-${type}--icon`,
        count: 'community-post-reactions-count--label',
      },
      savedContent: {
        button: 'community-saved-content--button',
        counter: 'community-saved-content--counter',
        icon: 'community-saved-content--icon',
      },
    },
    post: {
      listItem: 'community-post--list-item',
      view: 'community-post--view',
      button: 'community-post--button',
      category: {
        view: 'community-post-category--view',
      },
      avatar: {
        view: 'community-post-avatar--view',
        image: (aliasNumber: number) => `community-post-avatar--image${aliasNumber}`,
      },
      durationTime: {
        view: 'community-post-duration-time--view',
      },
      comment: {
        button: 'community-post-comment--button',
        label: 'community-post-comment--label',
      },
      support: {
        button: 'community-post-support--button',
        label: 'community-post-support--label',
      },
    },
    postCommentsList: {
      view: 'community-post-comments-list--view',
      commentCard: (id: string) => `community-post-comment-card--${id}`,
    },
    all: {
      view: 'community-feed-all--view',
      newPosts: 'community-feed-all-new-posts-button',
    },
    my: {
      view: 'community-feed-my--view',
    },
    page: {
      button: {
        addPost: 'community-page-add-post--button',
        getNewPosts: 'community-page-get-new-posts--button',
      },
    },
    rulesDialog: {
      view: 'community-rules-dialog-view',
      button: {
        cancel: 'community-rules-dialog-deny--button',
        agree: 'community-rules-dialog-agree--button',
        terms: 'community-rules-dialog-terms-and-conditions--link',
      },
    },
    deleteDialog: {
      view: 'community-delete-dialog-view',
      button: {
        cancel: 'community-delete-dialog-cancel--button',
        delete: 'community-delete-dialog-delete--button',
        close: 'community-delete-dialog-close--button',
      },
    },
    addPost: {
      view: 'community-add-post-view',
      input: {
        message: 'community-add-post-message--input',
        category: 'community-add-post-category--input',
      },
      button: {
        submit: 'community-add-post-submit--button',
        cancel: 'community-add-post-cancel--button',
      },
      agreement: {
        view: 'community-agreement-dialog--view',
        input: 'community-agreement-dialog-checkbox--input',
        button: {
          continue: 'community-agreement-dialog-continue---button',
          close: 'community-agreement-dialog-close--button',
        },
      },
    },
    addComment: {
      button: {
        submit: 'community-add-comment-submit--button',
      },
      input: {
        message: 'community-add-comment-message--input',
      },
    },
    tabPanel: {
      view: 'community-tab-panel--view',
    },
  },
  helpCenter: {
    contactSupport: {
      ticketNumber: 'help-center-contact-support--label',
    },
  },
  explore: {
    page: {
      view: 'explore-page--view',
      loading: 'explore-page-loading--view',
      tabBar: 'explore-page-tab-bar--view',
      tabs: (label: string) => `explore-page-tab-bar-${label}--button`,
    },
    category: {
      page: {
        view: 'explore-category-page--view',
      },
    },
    journey: {
      page: {
        view: 'explore-journey-page--view',
        content: {
          view: 'explore-journey-page-content--view',
          header: {
            image: 'explore-journey-page-content-header-image--view',
            title: 'explore-journey-page-content-header-title--view',
            description: 'explore-journey-page-content-header-description--view',
            progressPercentage: 'explore-journey-page-content-header-progress-percentage--view',
            progressBar: 'explore-journey-page-content-header-progress-bar--view',
            progressLeft: 'explore-journey-page-content-header-progress-left--view',
          },
        },
      },
    },
    filterButton: {
      checkIcon: 'explore-filter-button-check--icon',
      circleIcon: 'explore-filter-button-circle--icon',
    },
    contentCard: {
      view: (id: string) => `explore-content-card--view`,
      title: 'explore-content-card-title--label',
      description: 'explore-content-card-description--label',
      image: 'explore-content-card-image--view',
      icon: {
        slider: 'explore-content-card-icon-slider--view',
        article: 'explore-content-card-icon-article--view',
        video: 'explore-content-card-icon-video--view',
        view: 'explore-content-card-icon-view--view',
        completed: 'explore-content-card-icon-completed--view',
      },
    },
    slider: {
      root: 'explore-slider--root',
    },
    reactions: {
      root: 'explore-reactions--root',
      button: 'explore-reactions--button',
      solidIcon: 'explore-reactions-solid--icon',
      outlineIcon: 'explore-reactions-outline--icon',
    },
    guidelines: {
      button: 'explore-guidelines--button',
    },
  },
  visitChat: {
    view: 'visit-chat--view',
    visitChatMessagesList: {
      view: 'visit-chat-messages-list--view',
      message: (id: any) => `visit-chat-messages-card--view--${id}`,
    },
    input: 'visit-chat--input',
    button: 'visit-chat-send-message--button',
  },
  common: {
    dialogs: {
      confirmClose: {
        view: 'confirm-close-modal--view',
        confirm: 'confirm-close-modal-confirm--button',
        cancel: 'confirm-close-modal-cancel--button',
      },
    },
    unreadBadge: 'common-unread-badge--label',
  },
} as const

export default TestIds
